<template>
  <section class="fisa-pacient template relative w100 bg-white flex columns">
    <header>
      <figure class="flex flex-between">
        <!--        <img src="../assets/antet-img.jpg" alt="antet">-->
        <img src="../assets/logo.png" alt="logo" />
      </figure>
    </header>

    <h1 class="text-center">FIȘĂ MEDICALĂ</h1>

    <main>
      <ul class="date-pacient">
        <li>
          <b>Nume:</b>
          <span class="capitalize"
            >&nbsp;&nbsp;{{ pacient.nume }} {{ pacient.prenume }}</span
          >
        </li>
        <li><b>Telefon:</b> &nbsp;&nbsp;{{ pacient.telefon }}</li>
        <li>
          <b>Varsta:</b> &nbsp;&nbsp;{{
            pacient.varsta && pacient.varsta.replace("(vârsta)", "")
          }}
        </li>
      </ul>

      <ul class="lista-tratamente">
        <li v-for="(item, index) in pacient.history" :key="index">
          <div v-if="item.type !== 'scrisoare'">
            <span> <b>Tratament:</b> {{ item.denumire }} </span>
            <span> <b>Dinte:</b> {{ item.dinte }} </span>
            <span>
              <b>Data:</b> {{ moment(item.createdAt).format("DD MMM YYYY") }}
            </span>
            <span class="detalii"> <b>Detalii:</b> {{ item.detalii }} </span>
            <figure class="" v-if="item.pics && item.pics.length > 0">
              <img
                class="w90"
                v-for="(pic, index) in item.pics"
                :key="index"
                :src="`/history-imgs/${pacient._id}/${pic.name}`"
                alt=""
              />
              <div class="mtop14px"></div>
            </figure>
            <br /><br />
          </div>
        </li>
      </ul>
    </main>

    <footer class="flex flex-between">
      <span>www.premium-dental.ro</span>
      <!--      <figure>-->
      <!--        <img src="../assets/footer-fisa-pacient.jpg" alt="footer">-->
      <!--      </figure>-->
    </footer>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { historyModal } from '../mixins/history-modal';
  import moment from 'moment';
  import 'moment/locale/ro';
  moment.locale('ro');
  export default {
    name: 'fisa-pacient',
    mixins: [historyModal],
    data () {
      return {
        moment
      };
    },
    computed: {
      ...mapState({
        pacient: 'pacient'
      })
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  .fisa-pacient {
    .lista-tratamente {
      margin-top: 80px;
      font-size: 1.6rem;
      li {
        margin-top: 36px;
        margin-bottom: 24px;
        & > div {
          border-bottom: 1px solid @light-grey;
        }

        span {
          line-height: 2.8rem;
          display: block;
          b {
            display: inline-block;
            width: 100px;
          }
        }
      }

      figure {
        width: 100%;
        margin-top: 20px;
        img {
          align-self: center;
        }
      }

      .detalii {
        text-align: justify;
      }
    }

    footer {
      margin-top: auto;
      /*margin-bottom: 40px;*/
      padding: 60px 60px 0;
      align-items: flex-end;
      span {
        font-size: 2rem;
      }
    }
  }
</style>
